import React, { useState } from 'react'
import emailjs from 'emailjs-com';
import './Locations.scss'
import { Helmet } from "react-helmet";
import NavigatorPath from "../../Tools/NavigatorPath/NavigatorPath"
import { useNavigate } from 'react-router-dom';
import CitiesData from '../../Components/HomePageCity/CitiesData'

export default function Locations() {
    const navigate = useNavigate()


    return (
        <div className="Locations">
            <Helmet>
                {/* <!-- TITLE -->  */}
                {/* <!-- Maximum of 65 characters --> */}
                <title>Chimney Sweep Services Near You - Golden Chimney Sweep Locations</title>
                {/* <!-- Maximum 35 characters --> */}
                <meta name="title" property="title" content="Chimney Sweep Services Near You - Golden Chimney Sweep Locations" data-react-helmet="true" />
                <meta name="og:title" property="og:title" content="Chimney Sweep Services Near You - Golden Chimney Sweep Locations" data-react-helmet="true" />
                {/* <!-- META --> */}
                <meta name="og:locale" property="og:locale" content="en_US" data-react-helmet="true" />
                <meta name="og:type" property="og:type" content="article" data-react-helmet="true" />
                <meta name="og:image" property="og:image" content="article" data-react-helmet="true" />
                {/* <!-- Full link to the current webpage address -->  */}
                <meta name="og:url" property="og:url" content="https://www.golden-chimneysweep.net/locations" data-react-helmet="true" />
                {/* <!-- Maximum of 155 characters  --> */}
                <meta name="description" content="Explore Golden Chimney Sweep locations across multiple cities. We provide expert Chimney Sweep repair, installation, and maintenance services near you. Find a location closest to you and schedule a service today!" data-react-helmet="true" />
                {/* <!-- Maximum 65 characters --> */}
                <meta name="og:description" property="og:description" content="Explore Golden Chimney Sweep locations across multiple cities. We provide expert Chimney Sweep repair, installation, and maintenance services near you. Find a location closest to you and schedule a service today!" data-react-helmet="true" />
                <meta name="og:site_name" property="og:site_name" content="Chimney Sweep Services Near You - Golden Chimney Sweep Locations" data-react-helmet="true" />
                <meta name="keywords" content="chimney sweep, chimney Inspection, Chimney Inspection, Fireplace Inspection, Fireplace Cleaning, Chimney Cleaning, Chimney Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, Chimney Inspection, Fireplace Inspection, Fireplace Cleaning, Chimney Cleaning, Chimney Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, chimney services, chimney service, Chimney Inspection, Fireplace Inspection, Fireplace Cleaning, Chimney Cleaning, Chimney Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, Chimney Insulation, Chimney Installation, Chimney Liner Repair, Chimney Pointing, Chimney Rainp Installation, Chimney Repair, Chimney Crown Repair, Chimney Restoration, Chimney Flue Installation, Chimney Flue Repair, ​Chimney Construction, Chimney Vent Installation, Chimney Flashing, Flexible Chimney Liner Installation, Chimney Damper Repair, Chimneyp Repair, Fireplace Insulation, Fireplace Installation, Fireplace Liner Repair, Fireplace Pointing, Fireplace Rainp Installation, Fireplace Repair, Fireplace Crown Repair, Fireplace Restoration, Fireplace Flue Installation, Fireplace Flue Repair, Ffireplace Construction, Fireplace Vent Installation, Fireplace Flashing, Flexible fireplace Liner Installation, Fireplace Damper Repair, Fireplacep, Blog" data-react-helmet="true" />
            </Helmet>
            <div className="LocationsTitle">
                <h1>Chimney Sweep Services Near You - Golden Chimney Sweep Locations</h1>
                <h2>Explore Golden Chimney Sweep locations across multiple cities. We provide expert Chimney Sweep repair, installation, and maintenance services near you. Find a location closest to you and schedule a service today!</h2>
            </div>
            <div className="LocationsLsit">

                {/* Service */}
                {CitiesData.map((A, index) =>
                    <div className='LocationsBox'>
                        <a href={`/city/${A.url}`} className="Header1BoxMenuBox">
                            Chimney Sweep Repair In {A.City}, {A.State}
                        </a>
                    </div>

                )}


            </div>

            <NavigatorPath />

        </div>
    )
}

