import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Helmet } from "react-helmet";
import './SiteMap.scss';

// import { useLocation } from "react-router-dom";
import NavigatorPath from "../../Tools/NavigatorPath/NavigatorPath"
import { useEffect } from 'react';
import CitiesData from '../../Components/HomePageCity/CitiesData'


export default function SiteMap({ Blogs, FaqList, WebSitePages }) {
    const location = useLocation()
    const [SiteMapToArray, setSiteMapToArray] = useState([]);


    useEffect(() => {
        if (location.pathname.split('/')[2] == "website" || location.pathname.split('/')[1] == "sitemaps" || location.pathname.split('/').length <= 2 && location.pathname.split('/')[1] == "sitemap") {
            setSiteMapToArray(WebSitePages)
        } else if (location.pathname.split('/')[2] == "solutions") {
            setSiteMapToArray(WebSitePages)
        } else if (location.pathname.split('/')[2] == "blog") {
            setSiteMapToArray(Blogs)
        } else if (location.pathname.split('/')[2] == "faq") {
            setSiteMapToArray(FaqList)
        } else if (location.pathname.split('/')[2] == "socialmedia") {
            setSiteMapToArray(WebSitePages)
        }
    }, [location.pathname]);

    return (
        <div className="SiteMap">
            <Helmet>
                {/* <!-- TITLE -->  */}
                {/* <!-- Maximum of 65 characters --> */}
                <title>Golden Chimney Sweep | Site Map</title>
                {/* <!-- Maximum 35 characters --> */}
                <meta name="title" property="title" content="Golden Chimney Sweep | Site Map" data-react-helmet="true" />
                <meta name="og:title" property="og:title" content="Golden Chimney Sweep | Site Map" data-react-helmet="true" />
                {/* <!-- META --> */}
                <meta name="og:locale" property="og:locale" content="en_US" data-react-helmet="true" />
                <meta name="og:type" property="og:type" content="article" data-react-helmet="true" />
                <meta name="og:image" property="og:image" content="article" data-react-helmet="true" />
                {/* <!-- Full link to the current webpage address -->  */}
                <meta name="og:url" property="og:url" content="https://www.golden-chimneysweep.net/sitemap" data-react-helmet="true" />
                {/* <!-- Maximum of 155 characters  --> */}
                <meta name="description" content="Explore the comprehensive sitemap for Chimney Sweep. Find detailed links to our chimney sweeping, cleaning, inspection, and repair services to ensure your home's safety and efficiency." data-react-helmet="true" />
                {/* <!-- Maximum 65 characters --> */}
                <meta name="og:description" property="og:description" content="Explore the comprehensive sitemap for Chimney Sweep. Find detailed links to our chimney sweeping, cleaning, inspection, and repair services to ensure your home's safety and efficiency." data-react-helmet="true" />
                <meta name="keywords" content="Chimney sweeping, Chimney cleaning, Chimney inspection, Chimney repair, Fireplace maintenance, Creosote removal, Chimney safety, Chimney flue cleaning, Soot removal, Chimney maintenance services" data-react-helmet="true" />
                <meta name="og:site_name" property="og:site_name" content="Golden Chimney Sweep | Site Map" data-react-helmet="true" />
            </Helmet>
            <div className="SiteMapMenu">
                <a href="/">Website</a>
            </div>
            <div className="SiteMapDes">
                <div className='SiteMapDesBox'>
                    <a href="/">Home Page</a>
                                 {/* Service */}
                                 {CitiesData.map((A, index) =>
                        <a href={`/city/${A.url}`} className="Header1BoxMenuBox">
                            {A.City}, {A.State}
                        </a>
                    )}
                    <a href="/contact">Contact Us</a>
                    <a href="/book">Book Chimney Sweep Appointment Onlinelifornia</a>
                    <a href="/blog">Blog</a>
                    <a href="/faq">Faq</a>
                    <a href="/locations">Locations</a>
                    <a href={`/reviews`}>Reviews</a>
                    <a href="/services">Services</a>
                    <a href="/careers">Carees</a>
                    
                    <a href="/sitemap">Site Map</a>

                    <h1>Blog</h1>
                    <a href="/service/Chimney-Cap-Repair" className="Header1BoxMenuBox">
                        Chimney Cap Repairlifornia
                    </a>
                    <a href="/service/Chimney-Cleaning" className="Header1BoxMenuBox">
                        Chimney Cleaninglifornia
                    </a>
                    <a href="/service/Chimney-Construction" className="Header1BoxMenuBox">
                        Chimney Constructionlifornia
                    </a>
                    <a href="/service/Chimney-Crown-Repair" className="Header1BoxMenuBox">
                        Chimney Crown Repairlifornia
                    </a>
                    <a href="/service/Chimney-Flue-Installation" className="Header1BoxMenuBox">
                        Chimney Flue Installationlifornia
                    </a>
                    <a href="/service/Chimney-Flue-Repair" className="Header1BoxMenuBox">
                        Chimney Flue Repairlifornia
                    </a>
                    <a href="/service/Chimney-Inspection" className="Header1BoxMenuBox">
                        Chimney Inspectionlifornia
                    </a>
                    <a href="/service/Chimney-Installation" className="Header1BoxMenuBox">
                        Chimney Installationlifornia
                    </a>
                    <a href="/service/Chimney-Insulation" className="Header1BoxMenuBox">
                        Chimney Insulationlifornia
                    </a>
                    <a href="/service/Chimney-Cracks-Repair" className="Header1BoxMenuBox">
                        Chimney Cracks Repairlifornia
                    </a>
                    <a href="/service/Chimney-Firebox-Paint" className="Header1BoxMenuBox">
                        Chimney Firebox Paintlifornia
                    </a>
                    <a href="/service/Chimney-Firebox-Cleaining" className="Header1BoxMenuBox">
                        Chimney Firebox Cleaininglifornia
                    </a>
                    <a href="/service/Chimney-Firebox-Inspection" className="Header1BoxMenuBox">
                        Chimney Firebox Inspectionlifornia
                    </a>
                    <a href="/service/Chimney-Best-Company" className="Header1BoxMenuBox">
                        Chimney Best Companylifornia
                    </a>
                    <a href="/service/Firebox-Inspection" className="Header1BoxMenuBox">
                        Firebox Inspectionlifornia
                    </a>
                    <a href="/service/Firebox-Sweeping" className="Header1BoxMenuBox">
                        Firebox Sweepinglifornia
                    </a>
                    <a href="/service/Fireplace-Inspection" className="Header1BoxMenuBox">
                        Fireplace Inspectionlifornia
                    </a>
                    <a href="/service/Firepalce-Repair" className="Header1BoxMenuBox">
                        Firepalce Repairlifornia
                    </a>
                    <a href="/service/Chimney-Liner-Repair" className="Header1BoxMenuBox">
                        Chimney Liner Repairlifornia
                    </a>
                    <a href="/service/Chimney-Maintenance" className="Header1BoxMenuBox">
                        Chimney Maintenancelifornia
                    </a>
                    <a href="/service/Chimney-Pointing" className="Header1BoxMenuBox">
                        Chimney Pointinglifornia
                    </a>
                    <a href="/service/Chimney-Rain-Cap-Installation" className="Header1BoxMenuBox">
                        Chimney Rain Cap Installationlifornia
                    </a>
                    <a href="/service/Chimney-Repair" className="Header1BoxMenuBox">
                        Chimney Repairlifornia
                    </a>
                    <a href="/service/Chimney-Restoration" className="Header1BoxMenuBox">
                        Chimney Restorationlifornia
                    </a>
                    <a href="/service/Chimney-Flashing-Repair" className="Header1BoxMenuBox">
                        Chimney Flashing Repairlifornia
                    </a>
                    <a href="/service/Chimney-Liner-Installation" className="Header1BoxMenuBox">
                        Chimney Liner Installationlifornia
                    </a>
                    <a href="/service/Chimney-Damper-Repair" className="Header1BoxMenuBox">
                        Chimney Damper Repairlifornia
                    </a>
                </div>



            </div>
            <NavigatorPath />
        </div>
    )
}
